import React from 'react';
import PropTypes from 'prop-types';
import { scaledPixel } from '#/utils/vw';
import { FocusContentGrid } from '@accedo/vdkweb-tv-ui';
import { usePointer } from '#/context/PointerContext';
import {
  ArrowUpIcon as IconUp,
  ArrowDownIcon as IconDown,
  ArrowRightIcon as IconRight,
  ArrowLeftIcon as IconLeft
} from '#/components/Icons/Icons';
import gridTheme from './ContentGrid.scss';

const ContentGrid = (
  {
    width,
    height,
    itemWidth,
    itemHeight,
    headPadding,
    headPeek,
    spacing,
    tailPadding,
    tailPeek,
    useInternalArrows = true,
    theme,
    ...args
  },
  ref
) => {
  const { pointerEnabled } = usePointer();
  return (
    <FocusContentGrid
      width={width ? scaledPixel(width) : width}
      height={height ? scaledPixel(height) : height}
      itemWidth={itemWidth ? scaledPixel(itemWidth) : itemWidth}
      itemHeight={itemHeight ? scaledPixel(itemHeight) : itemHeight}
      headPadding={headPadding ? scaledPixel(headPadding) : headPadding}
      headPeek={headPeek ? scaledPixel(headPeek) : headPeek}
      spacing={spacing ? scaledPixel(spacing) : spacing}
      tailPadding={tailPadding ? scaledPixel(tailPadding) : tailPadding}
      tailPeek={tailPeek ? scaledPixel(tailPeek) : tailPeek}
      ref={ref}
      arrows={useInternalArrows !== false && pointerEnabled}
      arrowsTheme={gridTheme}
      arrowsIcons={{
        nextButton: args.vertical ? IconDown : IconRight,
        prevButton: args.vertical ? IconUp : IconLeft
      }}
      style={{
        overflow: 'visible'
      }}
      {...args}
    />
  );
};

const DynamicSizeContentGrid = React.forwardRef(ContentGrid);

const ContentGridPropTypes = {
  /**
   * When alignGrid is used, values for padding and peek
   * will be calculated so grid items are always placed
   * in the same positions when the grid is shifted.
   * set this to false to use the peek and padding values
   * provided.
   */
  alignGrid: PropTypes.bool,
  /**
   * when this flag is true it is expected that the grid is using css
   * transitions to animate shifting.
   * theme.animatedGrid class is added when animations should be
   * applied, and the grid will expect transitionend event to be
   * triggered when transition has completed.
   */
  animated: PropTypes.bool,
  /**
   * Buffer is the number of extra item spans to render outside the grid.
   * If there is no padding then this is needed so there is always
   * at least one item available that can gain focus
   */
  buffer: PropTypes.number,
  className: PropTypes.string,
  /** offset along the direction of the grid where to start render items */
  crossOffset: PropTypes.number,
  /** Static data array */
  data: PropTypes.array,
  /** directionallity from locale */
  dir: PropTypes.oneOf(['rtl', 'ltr']),
  /** Component used to render each item */
  DisplayComponent: PropTypes.func.isRequired,
  /** arbitrary props object to include when rendering each item defined in DisplayComponent */
  displayComponentProps: PropTypes.object,
  /** Datasource fetcher object. Should include getData, getTotalItems, isPaginationAllowed and hasData */
  ds: PropTypes.shape({
    /** Number of items to fetch. Default is 20 */
    pageSize: PropTypes.number,
    /**
     * Number of items defined as limit before doing another request to prefetch data
     * (ie: we fetch 20 items, define fetchItemLimit to 10, so when the finalIndex
     * is greater than the lenght - limit we do another request)
     */
    fetchItemLimit: PropTypes.number,
    hasData: PropTypes.func.isRequired,
    isPaginationAllowed: PropTypes.func.isRequired,
    getTotalNumber: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired
  }),
  /**
   * Padding values are used to position items away from grid edge.
   * Additional items will still render inside the padding area, to provide context when navigating.
   */
  headPadding: PropTypes.number,
  /**
   * Peek values represent the size of the area where items are rendered when grid has been shifted.
   * If headPadding is 32 and headPeek is 64, this means that the first item will be 32
   */
  headPeek: PropTypes.number,
  /** Used for internal calculations but may not reflect the actual size of the element. */
  height: PropTypes.number,
  /**
   * whether orientation should be horizontal (default is vertical) or not
   */
  horizontal: PropTypes.bool,
  /** initialState.position will decide what items are rendered */
  initialState: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number
  }),
  /**
   * Height of single items
   */
  itemHeight: PropTypes.number.isRequired,
  /**
   * Width of single items
   */
  itemWidth: PropTypes.number.isRequired,
  /**
   * property name of data object that represents a unique data item. often "id" for data.id.
   * used for key in react loop to indicate to react what the item in the loop is.
   */
  keyProperty: PropTypes.string.isRequired,
  /**
   * jump to opposing edge when trying to navigate beyond edge
   */
  loop: PropTypes.bool,
  /** nav object from @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** will trigger when grid position or focus changes */
  onChange: PropTypes.func,
  /** onClick is added to each grid item. Will be executed when the user interact with the item. */
  onClick: PropTypes.func,
  /** onClick is added to each grid item, Will be executed when the user place the focus in the item. */
  onFocus: PropTypes.func,
  /**
   * repeat items, endless grid
   * reserved for future use
   */
  repeat: PropTypes.bool,
  /**
   * whether dots needs to be shown or not
   */
  showDots: PropTypes.bool,
  /** Distance between each rendered item (in pixels) */
  spacing: PropTypes.number,
  /** Arbitrary css style object to apply to dom element */
  style: PropTypes.object,
  /**
   * Padding values are used to position items away from grid edge.
   * Additional items will still render inside the padding area, to provide context when navigating.
   *
   * Use this prop if you want to change from which item to scroll. This is defined in Pixels for both carousels and Grids
   */
  tailPadding: PropTypes.number,
  /**
   * Peek values represent the size of the area where items are rendered when grid has been shifted.
   * If headPadding is 32 and headPeek is 64, this means that the first item will be 32.
   */
  tailPeek: PropTypes.number,
  /** theme object from css module */
  theme: PropTypes.object,
  /**  whether to handle pointer arrows internally */
  useInternalArrows: PropTypes.bool,
  /**
   * whether orientation should be vertical (default) or not
   */
  vertical: PropTypes.bool,
  /**
   * width is not the width of the container element, but the width inside the container where items can render.
   * width and height are used for internal calculations but may not reflect the actual size of the element.
   * width and height also needs to be defined in theme/style to set the correct size of the grid.
   */
  width: PropTypes.number.isRequired
};

ContentGrid.propTypes = ContentGridPropTypes;

export default DynamicSizeContentGrid;
