import schedule from '#/static/images/olympics-schedule.png';
import upcoming1 from '#/static/images/olympics-upcoming-1.png';
import upcoming2 from '#/static/images/olympics-upcoming-2.png';

export default {
  total: 4,
  items: [
    {
      slug: 'only-murders-in-the-building',
      title: '',
      type: 'upcoming',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [
        {
          name: 'language',
          value: 'en'
        },
        {
          name: 'production_companies',
          value: '20th Television'
        },
        {
          name: 'homepage',
          value:
            'https://www.hulu.com/series/only-murders-in-the-building-ef31c7e1-cd0f-4e07-848d-1cbfedb50ddf'
        }
      ],
      images: [
        {
          type: 'poster',
          width: 2000,
          height: 3000,
          url: schedule,
          templateUrl: schedule,
          id: '/olympics-live-1.png'
        },
        {
          type: 'backdrop_clean',
          width: 3840,
          height: 2160,
          url: schedule,
          templateUrl: schedule,
          id: '/olympics-live-1.png'
        },
        {
          type: 'backdrop',
          width: 3840,
          height: 2160,
          url: schedule,
          templateUrl: schedule,
          id: '/olympics-live-1.png'
        },
        {
          type: 'poster_clean',
          width: 2000,
          height: 3000,
          url: schedule,
          templateUrl: schedule,
          id: '/olympics-live-1.png'
        },
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: schedule,
          templateUrl: schedule,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'star-trek-strange-new-worlds',
      title: 'Grand Opening | Monte Santo Di',
      sportsType: 'Luge',
      type: 'upcoming',
      description:
        'Follow Captain Christopher Pike, Science Officer Spock and Number One in the years before Captain Kirk boarded the U.S.S. Enterprise, as they explore new worlds around the galaxy.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-PG'
        }
      ],
      availableDate: 1651708800000,
      publishedDate: 1651708800000,
      metadata: [
        {
          name: 'language',
          value: 'en'
        },
        {
          name: 'production_companies',
          value:
            'Secret Hideout,Roddenberry Entertainment,CBS Studios,Weed Road Pictures,H M R X Productions'
        },
        {
          name: 'homepage',
          value:
            'https://www.paramountplus.com/shows/star-trek-strange-new-worlds'
        }
      ],
      images: [
        {
          type: 'poster',
          width: 2000,
          height: 3000,
          url: upcoming1,
          templateUrl: upcoming1,
          id: '/olympics-live-2.png'
        },
        {
          type: 'backdrop_clean',
          width: 3840,
          height: 2160,
          url: upcoming1,
          templateUrl: upcoming1,
          id: '/olympics-live-2.png'
        },
        {
          type: 'backdrop',
          width: 3840,
          height: 2160,
          url: upcoming1,
          templateUrl: upcoming1,
          id: '/olympics-live-2.png'
        },
        {
          type: 'poster_clean',
          width: 2000,
          height: 3000,
          url: upcoming1,
          templateUrl: upcoming1,
          id: '/olympics-live-2.png'
        },
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: upcoming1,
          templateUrl: upcoming1,
          id: '/olympics-live-2.png'
        }
      ],
      categories: [
        {
          title: 'Sci-Fi & Fantasy',
          description: 'Sci-Fi & Fantasy tvshows',
          id: 'tvshows-sci-fi-and-fantasy'
        }
      ],
      id: '103516',
      episodeCount: 20,
      tvSeasonCount: 2
    },
    {
      slug: 'dark-winds',
      title: 'Semi-Finals | Snow Arena B',
      sportsType: 'Figure Skating',
      type: 'upcoming',
      description:
        'This psychological thriller follows two Navajo police officers, Leaphorn and Chee, in the 1970s Southwest as their search for clues in a grisly double murder case forces them to challenge their own spiritual beliefs and come to terms with the trauma of their pasts.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1654992000000,
      publishedDate: 1654992000000,
      metadata: [
        {
          name: 'language',
          value: 'en'
        },
        {
          name: 'production_companies',
          value: ''
        },
        {
          name: 'homepage',
          value: 'https://www.amc.com/shows/dark-winds--1053387'
        }
      ],
      images: [
        {
          type: 'poster',
          width: 2000,
          height: 3000,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'backdrop_clean',
          width: 3840,
          height: 2160,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'backdrop',
          width: 3840,
          height: 2160,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'poster_clean',
          width: 2000,
          height: 3000,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        }
      ],
      categories: [
        {
          title: 'Crime',
          description: 'Crime tvshows',
          id: 'tvshows-crime'
        },
        {
          title: 'Drama',
          description: 'Drama tvshows',
          id: 'tvshows-drama'
        }
      ],
      id: '128904',
      episodeCount: 12,
      tvSeasonCount: 2
    },
    {
      slug: 'dark-winds',
      title: 'Semi-Finals | Snow Arena B',
      sportsType: 'Figure Skating',
      type: 'upcoming',
      description:
        'This psychological thriller follows two Navajo police officers, Leaphorn and Chee, in the 1970s Southwest as their search for clues in a grisly double murder case forces them to challenge their own spiritual beliefs and come to terms with the trauma of their pasts.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1654992000000,
      publishedDate: 1654992000000,
      metadata: [
        {
          name: 'language',
          value: 'en'
        },
        {
          name: 'production_companies',
          value: ''
        },
        {
          name: 'homepage',
          value: 'https://www.amc.com/shows/dark-winds--1053387'
        }
      ],
      images: [
        {
          type: 'poster',
          width: 2000,
          height: 3000,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'backdrop_clean',
          width: 3840,
          height: 2160,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'backdrop',
          width: 3840,
          height: 2160,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'poster_clean',
          width: 2000,
          height: 3000,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        },
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: upcoming2,
          templateUrl: upcoming2,
          id: '/olympics-live-3.png'
        }
      ],
      categories: [
        {
          title: 'Crime',
          description: 'Crime tvshows',
          id: 'tvshows-crime'
        },
        {
          title: 'Drama',
          description: 'Drama tvshows',
          id: 'tvshows-drama'
        }
      ],
      id: '128904',
      episodeCount: 12,
      tvSeasonCount: 2
    }
  ]
};
