/**
 * @module services/query
 * @description
 * Service to fetch data to the OVP using queries
 */
import ovp from '#/providers/ovp';
import Item from '#/models/item/item';
import { PROVIDER_TYPE } from '#/config/constants';

import interpolateTextWithObject from '#/utils/interpolateTextWithObject';

import fetcher from '../../helpers/fetcher';

import dummyHero from './dummy-hero';
import dummyLive from './dummy-live';
import dummySports from './dummy-sports';
import dummyUpcoming from './dummy-upcoming';

const dummyQueryMap = {
  hero: dummyHero,
  live: dummyLive,
  sports: dummySports,
  upcoming: dummyUpcoming
};

const getDummyItems = query => {
  const splitQuery = query.split('-');

  if (splitQuery[0] === 'dummy') {
    return dummyQueryMap[splitQuery[1]];
  }
};

/**
 * @param {String} query The query for the OVP.
 * @param {String} contextData Context for being interpolated with the query.
 * @param {Number} from Init index for retriving data.
 * @param {Number} to Final index for retriving data.
 * @param {Number} itemsPerPage Max number of item per query.
 * @param {String} sortBy Key for ordering data.
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  sortBy = ''
}) => {
  const dummyItems = getDummyItems(query);

  if (dummyItems) {
    return {
      items: dummyItems.items.map(item => Item(item)),
      total: dummyItems.items.length
    };
  }

  const interpolatedQuery = interpolateTextWithObject(query, contextData);

  const result = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-query-${interpolatedQuery}-${pageNumber}`,
    fetchFn: () =>
      ovp.getItemsByQuery({
        query: interpolatedQuery,
        pageNumber,
        sortBy,
        itemsPerPage
      })
  });

  if (!result) {
    console.warn(`[debug] Not response from query: ${query}`);
  }

  return {
    items: result?.items?.map(item => Item(item)),
    total: result?.total
  };
};

export { getItemsByQuery };
