import bob from '#/static/images/olympics-sports-bob.png';
import live2 from '#/static/images/olympics-live-2.png';
import live3 from '#/static/images/olympics-live-3.png';

export default {
  total: 4,
  items: [
    {
      slug: 'only-murders-in-the-building',
      title: 'Bobsleigh',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Snowboarding',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Curling',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Speed Skiing',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Biathlon',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Speed Skating',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Figure Skating',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    },
    {
      slug: 'only-murders-in-the-building',
      title: 'Ski Jumping',
      description:
        'Three strangers who share an obsession with true crime suddenly find themselves wrapped up in one.',
      credits: [],
      parentalRatings: [
        {
          scheme: 'USTVPG',
          rating: 'TV-MA'
        }
      ],
      availableDate: 1630368000000,
      publishedDate: 1630368000000,
      metadata: [],
      images: [
        {
          type: 'logo',
          width: 2000,
          height: 972,
          url: bob,
          templateUrl: bob,
          id: '/olympics-live-1.png'
        }
      ],
      categories: [
        {
          title: 'Comedy',
          description: 'Comedy tvshows',
          id: 'tvshows-comedy'
        },
        {
          title: 'Mystery',
          description: 'Mystery tvshows',
          id: 'tvshows-mystery'
        }
      ],
      id: '107113',
      episodeCount: 30,
      tvSeasonCount: 3
    }
  ]
};
