import React, { useEffect, useState, useContext, memo } from 'react';

import { withFocus } from '@accedo/vdkweb-navigation';
import { AuthContext } from '#/context/AuthContext';
import { CUSTOM_IMAGE_FORMAT } from '#/components/Item/itemHelper';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import getImageByType from '#/utils/getImageByType';
import { getTranslation } from '#/services/i18n';
import {
  CONTAINER_ITEM_TYPES,
  IMAGE_TYPE,
  ITEM_TYPES
} from '#/config/constants';
import Nav from '#/interfaces/Nav';
import { AssetItemData } from '#/interfaces/AssetItemData';
import LiveBadge from '#/static/images/live-badge.png';
import style from './assetItem.scss';

interface AssetItem {
  data: AssetItemData;
  nav: Nav;
  onClick: () => any | void;
  type: string;
  isContinueWatching: boolean;
  width: number | string;
  height: number | string;
  theme: any;
}

const CARD_BORDER_WIDTH_PX = 4;

const OrigAssetItem = ({
  data,
  nav,
  onClick,
  type,
  isContinueWatching,
  width: widthProp,
  height: heightProp,
  theme
}: AssetItem) => {
  const [width] = useState(widthProp);
  const [height] = useState(heightProp);
  const [poster, setPoster] = useState('');
  const { viewAll, displayText, rating, startTime } = data;

  const authContext = useContext(AuthContext);
  const isKidProfile = authContext.getUser()?.isKidProfile;
  const loadImage = () => {
    if (!type || !data.images?.length) {
      return;
    }
    if (isContinueWatching) {
      type = ITEM_TYPES.Bookmark;
    }
    const imageFormat = CUSTOM_IMAGE_FORMAT[type] || IMAGE_TYPE.POSTER;

    const image = getImageByType(data.images, imageFormat);
    setPoster(image);
  };

  const getBackgroundClassName = (isFocused?: boolean, isViewAll?: boolean) => {
    if (isFocused) {
      if (isViewAll) {
        return `${style.background} ${style.viewAllBackground} ${style.backgroundFocused}`;
      }
      return `${style.background} ${style.backgroundFocused}`;
    }
    if (isViewAll) {
      return `${style.background} ${style.viewAllBackground} `;
    }
    return style.background;
  };

  const getWatchTimeLeft = () => {
    let duration = 0;
    if (data.duration) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      duration = parseInt(data.duration.match(/\d+/)[0], 10);
    }
    const resumeTimeInMinutes = data.resumeTime / (1000 * 60);
    const timeLeftInMinutes = duration - resumeTimeInMinutes;
    return `${Math.floor(timeLeftInMinutes)} mins remaining`;
  };

  useEffect(() => {
    loadImage();
  }, []);

  const typeClassName = style[type] || '';

  const backgroundClassName = getBackgroundClassName(nav.isFocused, viewAll);

  const titleTheme =
    data.type === CONTAINER_ITEM_TYPES.Bookmark
      ? `${style.title} ${style['title-continue-watching']}`
      : style.title;

  const contentContainerClassName = nav.isFocused
    ? style.contentContainerFocused
    : `${style.contentContainer} ${typeClassName}`;

  const textBoxSize = { width: +width - CARD_BORDER_WIDTH_PX };

  const containerSize = { width, height };

  const imageClassName =
    type === ITEM_TYPES.Square ? `${style['logo-image']}` : '';

  const showTitle =
    type === ITEM_TYPES.Bookmark ||
    type === ITEM_TYPES.CategoriesLandscape ||
    type === ITEM_TYPES.SeasonEpisode ||
    type === ITEM_TYPES.GridLandscape ||
    type === ITEM_TYPES.Wide;

  const showWatchTime =
    data.type === CONTAINER_ITEM_TYPES.Bookmark ||
    data.type === CONTAINER_ITEM_TYPES.Episode;

  const blurBackground = () => {
    const element = document.getElementById('asset-background-img');
    if (element) {
      element.style.filter = 'blur(5px)';
    }
  };

  const clearBackground = () => {
    const element = document.getElementById('asset-background-img');
    if (element) {
      element.style.filter = 'blur(0px)';
    }
  };

  const getItemStyle = () => {
    if (type === ITEM_TYPES.Portrait) {
      return style.movieLink;
    }
    if (type === ITEM_TYPES.SearchResult) {
      return style.searchLink;
    }
    return style.link;
  };

  return (
    <div style={containerSize} className={style.container}>
      <div
        className={`link ${getItemStyle()}`}
        role="link"
        onClick={onClick}
        aria-label={data.title}
      >
        <div
          className={
            isKidProfile
              ? `${backgroundClassName} ${style.kidProfile}`
              : backgroundClassName
          }
          onMouseEnter={nav.onMouseOver}
          onMouseLeave={nav.onMouseOut}
          onMouseOver={blurBackground}
          onMouseOut={clearBackground}
        >
          {data.type === 'live' && (
            <img alt="" className={style.liveBadge} src={LiveBadge} />
          )}
          <ImageWithPreload
            src={poster}
            className={imageClassName}
            viewAll={viewAll ?? (data.type === 'live' || false)}
          />

          {viewAll ? (
            <div className={style.viewAll}>
              <div>{getTranslation('viewAll')}</div>
              <div>{displayText}</div>
            </div>
          ) : (
            data.type !== CONTAINER_ITEM_TYPES.Live &&
            data.progress && (
              <div className={theme.progress}>
                <span style={{ width: `${data.progress}%` }} />
              </div>
            )
          )}
        </div>
      </div>
      {!viewAll && (
        <div className={contentContainerClassName} style={textBoxSize}>
          {showTitle && (
            <>
              <div className={style.titleContainer}>
                {data.sportsType && (
                  <div className={style.subtitle}>{data.sportsType}</div>
                )}
                <div className={titleTheme}>{data.title}</div>
              </div>
              {(rating || startTime) && (
                <nav className={style.rating}>
                  {rating && !startTime && (
                    <img
                      src={require('#/static/images/starIcon.png')}
                      alt="start icon"
                      className={style.starIcon}
                    />
                  )}
                  {rating || startTime}
                </nav>
              )}
            </>
          )}
          {showWatchTime && (
            <div className={style.watchTime}>{getWatchTimeLeft()}</div>
          )}
        </div>
      )}
    </div>
  );
};

const propsAreEqual = (prevProps: AssetItem, nextProps: AssetItem) => {
  const { data: prevData, nav: prevNav } = prevProps || {};
  const { data: nextData, nav: nextNav } = nextProps || {};

  return (
    prevData.displayText === nextData.displayText &&
    prevData.viewAll === nextData.viewAll &&
    prevNav.isFocused === nextNav.isFocused &&
    prevNav.nextleft === nextNav.nextleft &&
    prevNav.nextright === nextNav.nextright &&
    prevNav.nextdown === nextNav.nextdown &&
    prevNav.nextup === nextNav.nextup
  );
};

const AssetItem = withFocus(OrigAssetItem);

export default memo(AssetItem, propsAreEqual);
