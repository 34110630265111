import olympicsBg from '#/static/images/olympics-bg.png';

export default {
  total: 0,
  items: [
    {
      slug: 'twisters',
      title: 'Winter Olympics 2026',
      originalTitle: 'Twisters',
      description:
        'As storm season intensifies, the paths of former storm chaser Kate Carter and reckless social-media superstar Tyler Owens collide when terrifying phenomena never seen before are unleashed. The pair and their competing teams find themselves squarely in the paths of multiple storm systems converging over central Oklahoma in the fight of their lives.',
      type: 'movie',
      availableDate: 1720569600000,
      publishedDate: 1720569600000,
      contents: [
        {
          url:
            'http://download.blender.org/peach/bigbuckbunny_movies/BigBuckBunny_320x180.mp4',
          format: 'mp4',
          width: 640,
          height: 360,
          language: 'en',
          duration: 7380000,
          geoLock: false,
          id: 'vid_718821'
        }
      ],
      credits: [],
      parentalRatings: [
        {
          scheme: 'MPAA',
          rating: 'PG'
        }
      ],
      metadata: [
        {
          name: 'language',
          value: 'en'
        },
        {
          name: 'production_companies',
          value:
            'Universal Pictures,Warner Bros. Pictures,Amblin Entertainment,Domain Entertainment'
        },
        {
          name: 'production_countries',
          value: 'US'
        },
        {
          name: 'homepage',
          value: 'https://www.twisters-movie.com'
        },
        {
          name: 'imdb_id',
          value: 'tt12584954'
        }
      ],
      images: [
        {
          type: 'poster',
          width: 2000,
          height: 3000,
          url: olympicsBg,
          templateUrl: olympicsBg,
          id: 'olympics-bg.png'
        },
        {
          type: 'backdrop_clean',
          width: 3840,
          height: 2160,
          url: olympicsBg,
          templateUrl: olympicsBg,
          id: 'olympics-bg.png'
        },
        {
          type: 'backdrop',
          width: 3840,
          height: 2160,
          url: olympicsBg,
          templateUrl: olympicsBg,
          id: 'olympics-bg.png'
        },
        {
          type: 'poster_clean',
          width: 2000,
          height: 3000,
          url: olympicsBg,
          templateUrl: olympicsBg,
          id: 'olympics-bg.png'
        },
        {
          type: 'logo',
          width: 4997,
          height: 616,
          url: olympicsBg,
          templateUrl: olympicsBg,
          id: 'olympics-bg.png'
        }
      ],
      categories: [
        {
          title: 'Action',
          description: 'Action movies',
          id: 'movies-action'
        },
        {
          title: 'Adventure',
          description: 'Adventure movies',
          id: 'movies-adventure'
        },
        {
          title: 'Thriller',
          description: 'Thriller movies',
          id: 'movies-thriller'
        }
      ],
      id: '718821'
    }
  ]
};
